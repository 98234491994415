import { loadPlugin } from './loadPlugin';

/**
 * 封装部分地图工具
 */

export class MapMouseTool {
  constructor(map) {
    this.map = map;
    this.mouseTool = null;
    this.drawCallback = null;
    this.drawErrorCallback = null;

    this.onDraw = this.onDraw.bind(this);
  }

  /**
   * 销毁
   */
  destroy() {
    this.close();
    this.mouseTool.off('draw', this.onDraw);
    this.mouseTool = null;
  }

  /**
   * 关闭工具
   */
  close() {
    this.mouseTool?.close(true);
  }

  /**
   * 绘制折线
   * https://lbs.amap.com/api/javascript-api/reference/overlay#polyline
   * PolylineOptions
   */
  drawPolyline(opts = {}) {
    return this.draw('polyline', opts);
  }
  /**
   * 绘制多边形
   * https://lbs.amap.com/api/javascript-api/reference/overlay#polygon
   * PolygonOptions
   */
  drawPolygon(opts = {}) {
    return this.draw('polygon', opts);
  }
  /**
   * 绘制矩形
   * https://lbs.amap.com/api/javascript-api/reference/overlay#rectangle
   * RectangleOptions
   */
  drawRectangle(opts = {}) {
    return this.draw('rectangle', opts);
  }
  /**
   * 绘制圆形
   * https://lbs.amap.com/api/javascript-api/reference/overlay#circle
   * CircleOptions
   */
  drawCircle(opts = {}) {
    return this.draw('circle', opts);
  }

  /**
   * 绘制
   */
  async draw(type = 'polyline', opts = {}) {

    // 加载插件
    await loadPlugin(['AMap.MouseTool']);

    this.mouseTool = new AMap.MouseTool(this.map);
    this.mouseTool.on('draw', this.onDraw);


    if (this.drawCallback) throw new Error('已经在绘制了');

    this.mouseTool[type](opts);

    return new Promise((resolve, inject) => {
      this.drawCallback = resolve;
      this.drawErrorCallback = inject;
    });
  }

  onDraw({ obj }) {
    try {

      this.mouseTool.close(true);

      const overlay = {
        position: obj.getPosition && obj.getPosition(), //获取点标记的位置
        center: obj.getCenter && obj.getCenter(), //获取圆中心点
        radius: obj.getRadius && obj.getRadius(), //获取圆形的半径
        path: obj.getPath && obj.getPath(), //节点数组。其中lat和lng是经纬度参数
        bounds: obj.getBounds && obj.getBounds(), //矩形范围
        area: obj.getArea && obj.getArea(), //获取多边形的面积（单位：平方米）
        angle: obj.getAngle && obj.getAngle(), //获取点标记的旋转角度
        length: obj.getLength && obj.getLength(), //获取折线的总长度（单位：米）
        extData: obj.getExtData && obj.getExtData(), //获取用户自定义属性
      };

      this.drawCallback?.(overlay);
    } catch (err) {
      this.drawErrorCallback?.(err);
    } finally {
      this.drawCallback = null;
      this.drawErrorCallback = null;
    }
  }
}


import { loadPlugin } from './loadPlugin';

/**
 * 创建地图实例
 */
export function createMap(el, config = {}) {
  if (!window.AMap) return;
  if (!el) return;

  const map = new AMap.Map(el, {
    resizeEnable: true,
    expandZoomRange: true,
    // layers: [new AMap.TileLayer.Satellite()],
    zoom: 12,
    center: [116.399145, 39.90844],
    ...config
  });


  // const mouseTool = new AMap.MouseTool(map);

  return map;
}


/**
 * 加载地图控件
 * @param {*} map 
 * https://lbs.amap.com/api/javascript-api/reference/map-control
 */
export function loadMapControl(map) {
  // 组合了旋转、倾斜、复位、缩放在内的地图控件，在3D地图模式下会显示
  // map.plugin(['AMap.ControlBar'], () => {
  //   map.addControl(new AMap.ControlBar());
  // });

  // 地图工具条插件，可以用来控制地图的缩放和平移
  loadPlugin(['AMap.ToolBar'])
    .then(() => {
      const toolBar = new AMap.ToolBar({ position: 'LT' });
      map.addControl(toolBar);
    });

  // 地图比例尺插件
  loadPlugin(['AMap.Scale'])
    .then(() => {
      const scale = new AMap.Scale();
      map.addControl(scale);
    });

  // 地图类型切换插件，用来切换固定的几个常用图层
  // 卫星, 路况等
  loadPlugin(['AMap.MapType'])
    .then(() => {
      map.addControl(
        new AMap.MapType({
          defaultType: 0,
          position: 'RT',
        })
      );
    });

  //加载鹰眼
  loadPlugin(['AMap.OverView'])
    .then(() => {
      map.addControl(new AMap.OverView());
    });
}
import { appConfig } from '@/config/appConfig';
import { createHttp } from '@/api/HttpHelper';
import { createMJHttp } from '@/api/MJRequest';

const axios = createHttp(appConfig.base2GUrl);
const MJAxios = createMJHttp(appConfig.MJBaseUrl);
const statisticsAxios = createHttp(appConfig.base2GUrl2);
const YWAxios = createHttp(appConfig.YWBaseUrl);

const post = (url, data, options) => axios.post(url, data, {
   headers: { "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8" },
   ...options
});

//获取品牌种类列表数据
export const getDeviceBrandPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/deviceBrand/getPage',
      method: 'POST',
      data
   });
};

//获取机械种类列表数据
export const getKindInfoAndNumber = (params) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/getKindInfoAndNumber',
      method: 'GET',
      params
   });
};

//获取品牌列表数据
export const getBrandInfoPageList = (params) => {
   return MJAxios.axios({
      url: '/ins-policy/insBrandInfo/getBrandInfoPageList',
      method: 'POST',
      params
   });
};

//获取配件列表
export const getZeroPartsListPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/getListPage',
      method: 'POST',
      loading: true,
      data
   });
};

//获取配件列表-后台
export const getZeroPartsPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/getPage',
      method: 'POST',
      loading: true,
      data
   });
};

// //获取配件详情
export const getZeroPartsDetails = (data) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/getZeroPartsDetails',
      method: 'post',
      data
   });
};

// // 下载零配件导入模版
export const downloadTemplate = (params) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/downloadTemplate',
      method: 'GET',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      params
   });
};

// // // 导出零配件
// export const downloadTemplate = (params) => {
//    return MJAxios.axios({
//       url: '/basic-data/zeroParts/downloadTemplate',
//       method: 'GET',
//       loading: true,
//       responseType: 'blob',
//       timeout: 1800000,
//       params
//    });
// };

//导入配件
export const importZeroParts = (data) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/importZeroParts',
      method: 'POST',
      loading: true,
      data
   });
};

//删除配件
export const removeBatchZeroParts = (params) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/removeBatchZeroParts',
      method: 'GET',
      loading: true,
      params
   });
};

// 设备品牌数据
export const deviceBrandgetList = (data) => {
   return MJAxios.axios({
      url: '/basic-data/deviceBrand/getList',
      method: 'POST',
      loading: true,
      data
   });
};

// 添加/编辑 配件
export const zeroPartsAddOrUpdate = (data) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/zeroPartsAddOrUpdate',
      method: 'POST',
      loading: true,
      data
   });
};

// 配件操作日志
export const zeroPartsLogList = (data) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/zeroPartsLogList',
      method: 'POST',
      loading: true,
      data
   });
};


// 配件 供应商
export const suppliersPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/suppliersPage',
      method: 'POST',
      loading: true,
      data
   });
};

//操作日志详情
export const zeroPartsLogDetails = (params) => {
   return MJAxios.axios({
      url: '/basic-data/zeroParts/zeroPartsLogDetails',
      method: 'GET',
      loading: true,
      params
   });
};

//风控报告
export const reportSummaryData = (params, type) => {
   return MJAxios.axios({
      url: '/basic-data/risk-ctrl-project-report/summary-data/' + type,
      method: 'GET',
      loading: true,
      params
   });
};

// // 下载风控报告
export const downloadGenerateReport= (params, type) => {
   return MJAxios.axios({
      url: '/basic-data/risk-ctrl-project-report/generate-report/' + type,
      method: 'GET',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      params
   });
};


// 项目2.0 保单项目 - 列表分页
export const insProjectPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/projectPage',
      method: 'POST',
      loading: true,
      data
   });
};


// 项目2.0 保单项目 - 新增
export const insSaveProject = (data) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/saveProject',
      method: 'POST',
      loading: true,
      data
   });
};

// 项目2.0 保单项目 - 更新
export const insUpdateProject = (data) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/updateProject',
      method: 'POST',
      loading: true,
      data
   });
};


// 项目2.0 保单项目 - 子项目操作
export const updateSubProjects = (data) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/updateSubProjects',
      method: 'POST',
      loading: true,
      data
   });
};


// 项目2.0 保单项目 - 项目详情
export const getProjectDetail = (params) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/getProjectDetail',
      method: 'GET',
      loading: true,
      params
   });
};

// 项目2.0 保单项目 - 导出
export const insProjectExportFile= (data) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/exportFile',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};

// 项目2.0 风险项目 - 导出
export const rickProjectExportFile= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/export',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目2.0 保单项目 - 删除项目
export const deletePolicyProject = (params) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/deleteProject',
      method: 'GET',
      loading: true,
      params
   });
};


// 项目2.0 保单项目 - 保单查询用
export const pageListToSelect = (params) => {
   return MJAxios.axios({
      url: '/basic-data/insProject/pageListToSelect',
      method: 'GET',
      loading: true,
      params
   });
};


// 项目2.0 风险项目 - 获取保单项目
export const projectPolicyPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectPolicyPage',
      method: 'POST',
      loading: true,
      data
   });
};


// 项目2.0 风险项目 - 根据保单项目获取保单数据
export const projectPolicyList = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/policyList',
      method: 'GET',
      loading: true,
      params
   });
};


// 项目2.0 风险项目 - 查询用户列表
export const getProjectUserList = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/userList',
      method: 'post',
      loading: true,
      data
   });
};


// 项目2.0 风险项目 - 查询客户列表
export const projectCustomerPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectCustomerPage',
      method: 'post',
      loading: true,
      data
   });
};

// 项目3.0 风险项目 - 查询客户列表
export const policyCustomerPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/policyCustomerPage',
      method: 'post',
      loading: true,
      data
   });
};

// 项目2.0 风险项目 - 导出客户列表
export const exportCustomer= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportCustomer',
      method: 'post',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};

// 项目3.0 风险项目 - 导出客户列表
export const exportPolicyCustomer= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportPolicyCustomer',
      method: 'post',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目2.0 风险项目 - 根据车组获取设备数据
export const projectDevicePage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectDevicePage',
      method: 'post',
      loading: true,
      data
   });
};


// 项目2.0 风险项目 - 根据车组获取设备数据GET方式
export const projectDeviceList = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectDeviceList',
      method: 'get',
      loading: true,
      params
   });
};

// 项目2.0 风险项目 - 导出设备数据
export const exportProjectDevice= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportDevice',
      method: 'post',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目2.0 风险项目 - 保存项目
export const newSaveProject = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/saveProject',
      method: 'post',
      loading: true,
      data
   });
};


// 项目2.0 风险项目 - 项目列表
export const newRiskProject = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/page',
      method: 'post',
      loading: true,
      data
   });
};


// 项目2.0 风险项目 - 项目详情
export const newRiskProjectDetail = (id) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/detail/' + id,
      method: 'GET',
      loading: true,
   });
};


// 项目2.0 风险项目 - 分页获取查看用户列表
export const projectViewUserPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectViewUserPage',
      method: 'post',
      loading: true,
      data
   });
};


// 项目2.0 风险项目 - 分页获取培训记录数据
export const projectTrainingRecordPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectTrainingRecordPage',
      method: 'post',
      loading: true,
      data
   });
};

// 项目2.0 风险项目 - 导出培训记录数据
export const exportTrainingRecord= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportTrainingRecord',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目2.0 风险项目 - 分页获取项目机手数据
export const projectJsInfoPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectJsInfoPage',
      method: 'post',
      loading: true,
      data
   });
};

// 项目2.0 风险项目 - 导出机手数据
export const exportProjectJsInfo= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportJsInfo',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目2.0 风险项目 - 分页获取项目文件
export const projectFilePage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectFilePage',
      method: 'post',
      loading: true,
      data
   });
};


// 项目2.0 风险项目 - 分页获取项目案件数据
export const projectCasePage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectCasePage',
      method: 'post',
      loading: true,
      data
   });
};

// 项目2.0 风险项目 - 导出案件数据
export const exportProjectCase= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportCase',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目2.0 风险项目 - 分页获取项目保单数据
export const projectPolicyListPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/projectPolicyPage',
      method: 'post',
      loading: true,
      data
   });
};

// 项目2.0 风险项目 - 导出保单数据
export const exportProjectPolicy= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportPolicy',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// // 项目2.0 风险项目 - 删除项目客户数据
export const delProjectCustomer = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/delProjectCustomer',
      method: 'get',
      loading: true,
      params
   });
};


// // 项目2.0 风险项目 - 新增项目客户数据
export const addProjectCustomer = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/addProjectCustomer',
      method: 'get',
      loading: true,
      params
   });
};


// // 项目2.0 风险项目 - 删除项目用户数据
export const delViewUser = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/delViewUser',
      method: 'get',
      loading: true,
      params
   });
};


// // 项目2.0 风险项目 - 新增项目查看用户数据
export const addViewUser = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/addViewUser',
      method: 'get',
      loading: true,
      params
   });
};


// // 项目2.0 风险项目 - 绑定项目保单数据
export const bindPolicyProject = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/bindPolicyProject',
      method: 'get',
      loading: true,
      params
   });
};


// // 项目2.0 风险项目 - 绑定项目车组
export const bindVehicleGroup = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/bindVehicleGroup',
      method: 'get',
      loading: true,
      params
   });
};


// // 项目2.0 风险项目 - 删除项目
export const delRickProject = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/delProject',
      method: 'post',
      loading: true,
      data
   });
};


// // 项目2.0 风险项目 - 添加项目文件
export const addProjectFile = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/addProjectFile',
      method: 'post',
      loading: true,
      data
   });
};

// // 项目2.0 风险项目 - 删除项目文件
export const delProjectFile = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/delProjectFile',
      method: 'get',
      loading: true,
      params
   });
};

// // 项目2.0 风险项目 - 显示隐藏数据
export const swProjectDisplay = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/swDisplay',
      method: 'get',
      loading: true,
      params
   });
};


// // 项目2.0 风险项目 - 保存培训记录数据
export const saveTrainingRecordPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/saveTrainingRecordPage',
      method: 'post',
      loading: true,
      data
   });
};

// // 项目2.0 风险项目 - 培训记录数据详情
export const trainingRecordDetail = (params) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/trainingRecordDetail/' + params,
      method: 'get',
      loading: true,
   });
};


// // 项目2.0 风险项目 - 删除培训记录数据
export const delTrainingRecordPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/delTrainingRecordPage',
      method: 'post',
      loading: true,
      data
   });
};


// 项目3.0 查询保单标的列表
export const policySubjectPage = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/policySubjectPage',
      method: 'post',
      loading: true,
      data
   });
};

// 项目3.0 保单标的 - 导出
export const exportPolicySubjectPage= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportPolicySubjectPage',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目3.0 查询子项目列表
export const subjectProject = (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/subjectProject',
      method: 'post',
      loading: true,
      data
   });
};

// 项目3.0 子项目 - 导出
export const exportSubjectProject= (data) => {
   return MJAxios.axios({
      url: '/basic-data/project_v2/exportSubjectProject',
      method: 'POST',
      loading: true,
      responseType: 'blob',
      timeout: 1800000,
      data
   });
};


// 项目3.0 项目概览
export const projectIdxSummary = (params) => {
   return MJAxios.axios({
      url: `/basic-data/project_v2/projectIdxSummary/${params}`,
      method: 'get',
      loading: true,
      params
   });
};


// 项目3.0 项目指标
export const projectTargetInfo = (params) => {
   return MJAxios.axios({
      url: `/basic-data/project_v2/projectTargetInfo/${params.id}`,
      // /${params.year}
      method: 'get',
      loading: true,
      // params
   });
};






<template>
  <el-dialog
    :title="editId ? '编辑' : '添加'"
    :visible.sync="dialogVisible"
    custom-class="add-parts-retrieval-dialog"
    width="930px"
    :before-close="handleClose"
  >
    <div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="150px"
        class="demo-ruleForm"
      >
        <el-form-item label="配件名称" prop="partName">
          <el-input style="width:268px;" v-model="ruleForm.partName"></el-input>
        </el-form-item>
        <el-form-item label="配件型号" prop="partModel">
          <el-input style="width:268px;" v-model="ruleForm.partModel"></el-input>
        </el-form-item>
        <el-form-item label="配件类型" prop="deviceType">
          <el-select style="width:268px;" filterable v-model="ruleForm.deviceType" placeholder="请选择" @change="selectMachineryProductType">
            <el-option v-for="item in machineryProductTypeList"
                    :key="item.dictType"
                    :label="item.dictName"
                    :value="item.dictName"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备种类" prop="deviceCategory">
                <el-select style="width:268px;" class="row-selectAddress"
                  v-model="ruleForm.deviceCategory"
                  filterable
                  placeholder="设备种类">
                  <el-option v-for="item in machineryEquipmentTypeList"
                    :key="item.dictValue"
                    :label="item.dictLabel"
                    :value="item.dictLabel"></el-option>
                </el-select>
        </el-form-item>
        <el-form-item label="设备型号" prop="deviceModelNumber">
          <el-input style="width:268px;" v-model="ruleForm.deviceModelNumber"></el-input>
        </el-form-item>
        <el-form-item label="配件品牌" prop="partBrand">
          <el-select style="width:268px;" v-model="ruleForm.partBrand" placeholder="请选择">
            <el-option v-for="item,index in deviceBrandgetData" :key="index" :label="item.brandName" :value="item.brandName"></el-option>
          </el-select>
        </el-form-item>
        <div>
          <el-table :data="ruleForm.suppliersList" border style="width: 100%">
            <el-table-column prop="supplierName" label="供应商名称" width="170">
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> 供应商名称
              </template>
              <template slot-scope="scope">
                <el-form-item
                  label-width="0"
                  :prop="'suppliersList.' + scope.$index + '.supplierName'"
                  :rules="{
                    required: true,
                    message: '供应商名称不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    type="text"
                    placeholder="请输入内容"
                    v-model="ruleForm.suppliersList[scope.$index].supplierName"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="partType" label="零件类型" width="118">
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> 零件类型
              </template>
              <template slot-scope="scope">
                <el-form-item
                  label-width="0"
                  :prop="'suppliersList.' + scope.$index + '.partType'"
                  :rules="{
                    required: true,
                    message: '类型不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-select
                    v-model="ruleForm.suppliersList[scope.$index].partType"
                    placeholder="请选择"
                  >
                    <el-option
                      v-for="item in partTypeOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column prop="partOffer" width="100" label="零件报价">
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> 零件报价
              </template>
              <template slot-scope="scope">
                <el-form-item
                  label-width="0"
                  :prop="'suppliersList.' + scope.$index + '.partOffer'"
                  :rules="{
                    required: true,
                    message: '报价不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    type="number"
                    placeholder="请输入内容"
                    v-model="ruleForm.suppliersList[scope.$index].partOffer"
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="supplierContact"
              width="136"
              label="供应商联系方式"
            >
              <template slot="header" slot-scope="scope">
                <span style="color: red">*</span> 供应商联系方式
              </template>
              <template slot-scope="scope">
                <el-form-item
                  label-width="0"
                  :prop="'suppliersList.' + scope.$index + '.supplierContact'"
                  :rules="{
                    required: true,
                    message: '联系方式不能为空',
                    trigger: 'blur',
                  }"
                >
                  <el-input
                    placeholder="请输入内容"
                    v-model="
                      ruleForm.suppliersList[scope.$index].supplierContact
                    "
                    maxlength="11"
                    show-word-limit
                  ></el-input>
                </el-form-item>
              </template>
            </el-table-column>
            <el-table-column
              prop="supplierAddress"
              width="228"
              label="供应商地址"
            >
              <template slot-scope="scope">
                <div>{{ ruleForm.suppliersList[scope.$index].supplierAddress }}
                <span class="address-button" @click="handleShowPositionDialog(scope.$index)">{{ ruleForm.suppliersList[scope.$index].supplierAddress ? '重选' : '地图选点' }}</span></div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="操作">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  type="text"
                  style="color: #f64a2d"
                  @click="handleDelete(scope.$index, scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <div class="append-column" @click="handleAddSuppliers">+ 添加一行</div>
        </div>
        <el-form-item label="配件状态" prop="enableStatus">
          <el-radio-group v-model="ruleForm.enableStatus">
            <el-radio :label="1">启用</el-radio>
            <el-radio :label="0">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="配件图片" prop="partImage">
          <el-upload
            class="avatar-uploader" accept="image/*" ref="uploadaaa"
            :show-file-list="false" :auto-upload="false" action="#"
            :on-change="beforeAvatarUpload" list-type="picture-card"
            multiple
            :limit="1">
            <img v-if="ruleForm.partImage" :src="ruleForm.partImage" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="备注" prop="remark">
          <el-input
            type="textarea"
            v-model="ruleForm.remark"
            rows="4"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleReset">重 置</el-button>
      <el-button type="primary" @click="handleSubmit">确 认</el-button>
    </span>
    <SelectAPointDialog v-model="isPositionDialog"
      type="addressObj"
      :addressValue="addressValue"
      @submit="handleAddressVal" />
  </el-dialog>
</template>

<script>
import { getBrandInfoPageList, zeroPartsAddOrUpdate, getZeroPartsDetails } from "@/api/basicData";
import SelectAPointDialog from "@/views/PartsRetrieval/components/SelectAPointDialog.vue";
import { OSSDirectPass } from "@/api/oss.js";
import {
  getDictList, //获取产品列表
  getDictType, //获取设备列表
} from "@/api/getManagementData.js";
export default {
  props: {
    showDialog: Boolean,
    editId: {
      type: [String,Number],
      default: ''
    } 
  },
  components: { SelectAPointDialog },
  model: {
    prop: "showDialog",
    event: "change",
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit("change", false);
        }else {
          this.$emit("change", true);
        }
      },
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.init();
          this.handleReset();
        }else {
          this.dialogVisible = false;
        }
      },
    },
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {
        partName: "",
        partModel: "",
        deviceModelNumber: '',
        deviceType: "",
        deviceCategory: "",
        remark: "",
        partBrand: "",
        enableStatus: "1",
        partImage: "",
        suppliersList: [
          {
            zeroPartsId: '',
            supplierName: "",
            partType: '',
            partOffer: '',
            supplierContact: "",
            supplierAddress: "",
            lon: '',
            lat: '',
          },
        ],
        suppliersIds: [],
      },
      rules: {
        partName: [
          { required: true, message: "请输入名称", trigger: "change" },
        ],
        partModel: [
          { required: true, message: "请输入型号", trigger: "change" },
        ],
        deviceType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        deviceCategory: [
          { required: true, message: "请选择种类", trigger: "change" },
        ],
        partBrand: [
          { required: true, message: "请选择品牌", trigger: "change" },
        ],
        enableStatus: [
          { required: true, message: "请选择状态", trigger: "change" },
        ],
      },
      partTypeOptions: [
        {
          label: "原厂件",
          value: 1,
        },
        {
          label: "副厂件",
          value: 2,
        },
        {
          label: "拆车件",
          value: 3,
        },
        {
          label: "厂商/代理商服务",
          value: 4,
        },
        {
          label: "外修厂服务",
          value: 5,
        },
      ],
      isPositionDialog: false,
      addressValue: "",
      suppliersIndex: '',
      deviceBrandgetData: [],
      machineryProductTypeList: [],
      machineryEquipmentTypeList: [],
      suppliersIds: []
    };
  },
  methods: {
    init() {
      getBrandInfoPageList({
        pageNum: 1,
        pageSize: 9999,
        current: 1,
        size: 9999
      }).then(res=>{
        // this.deviceBrandgetData = res.data.records;
        
        let nameList = res.data.records.map(val=> val.brandName);
        this.deviceBrandgetData = res.data.records.filter((item, index) => nameList.indexOf(item.brandName) === index);
      });
      getDictList({ type: '1' }).then(res => {
        if (res.flag === 1) {
          this.machineryProductTypeList = res.rows;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },
    handlePreview(e) {
      this.fileList = [e];
    },
    handleRemove() {
      this.fileList = [];
    },
    handleImport() {
      if (!this.fileList.length) {
        this.$message.error("请选择文件");
        return false;
      }
      let formData = new FormData();
      formData.append("file", this.fileList[0].raw);
      // formData.append("importZeroPartsReq", {
      //   userId: this.$store.state.userInfo.userId,
      //   userName: this.$store.state.userInfo.name,
      // });
      formData.append("userId", this.$store.state.userInfo.userId);
      formData.append("userName",this.$store.state.userInfo.name);

      // importZeroParts(formData)
      //   .then((res) => {
      //     this.$message.success("上传成功");
      //     this.dialogVisible = false;
      //     this.fileList = [];
      //     this.$emit("changeList");
      //   })
      //   .catch((err) => {
      //     setTimeout(() => {
      //       this.$message.error("上传失败");
      //     }, 3000);
      //   });
      // this.dialogVisible = false;
    },
    handleSubmit() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          this.ruleForm.suppliersIds = this.suppliersIds;
          this.ruleForm.userName = this.$store.state.userInfo.name;
          this.ruleForm.userId = this.$store.state.userInfo.userId;
          zeroPartsAddOrUpdate(this.ruleForm).then(res=>{
            this.$message.success('操作成功');
            this.dialogVisible = false;
            this.$emit('changeList')
          })
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleAddSuppliers() {
      this.ruleForm.suppliersList.push({
            zeroPartsId: '',
            supplierName: "",
            partType: '',
            partOffer: '',
            supplierContact: "",
            supplierAddress: "",
            lon: '',
            lat: '',
          })
    },
    handleDelete(ind, e) {
      if (e.id) {
        this.suppliersIds.push(e.id)
      }else if (this.ruleForm.suppliersList.length <= 1) {
        this.$message.error('至少保留一个供应商');
        return false;
      }
      this.ruleForm.suppliersList.splice(ind, 1);
    },
    handleAddressVal(e) {
      this.ruleForm.suppliersList[this.suppliersIndex].supplierAddress = e.completeAddress;
      this.ruleForm.suppliersList[this.suppliersIndex].lat = e.lat;
      this.ruleForm.suppliersList[this.suppliersIndex].lon = e.lon;
      this.isPositionDialog = false;
    },
    handleShowPositionDialog(ind) {
      this.suppliersIndex = ind;
      this.addressValue = this.ruleForm.suppliersList[this.suppliersIndex].supplierAddress;
      this.isPositionDialog = true;
    },
    // 获取设备类型列表
    getMachineryEquipmentTypeList() {
      getDictType({ dictType: this.machineryProductTypeList.find(val=> val.dictName == this.ruleForm.deviceType).dictType  }).then(res => {
        if (res.flag === 1) {
          this.machineryEquipmentTypeList = res.obj;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    // 选择产品类型
    selectMachineryProductType() {
      this.ruleForm.deviceCategory = '';
      this.getMachineryEquipmentTypeList();
    },
    async beforeAvatarUpload(file) {
      this.$refs.uploadaaa.clearFiles()
      let fileSize = file.size / 1024 / 1024;
      if (fileSize > 5) {
        this.$message({
          message: `上传的文件不能大于${ 5 }MB`,
          type: "warning",
        });
        return;
      }
      const url = await OSSDirectPass(file);
      this.ruleForm.partImage = url;
    },
    handleReset() {
      this.suppliersIds = [];
      if (this.editId) {
        getZeroPartsDetails({
          id: this.editId
        }).then(res=>{
          this.ruleForm =  res.data;
          this.ruleForm.suppliersList = this.ruleForm.supplierList;
        })
      }else {
        this.ruleForm = {
          partName: "",
          partModel: "",
        deviceModelNumber: '',
          deviceType: "",
          deviceCategory: "",
          remark: "",
          partBrand: "",
          enableStatus: 1,
          partImage: "",
          suppliersList: [
            {
              zeroPartsId: '',
              supplierName: "",
              partType: '',
              partOffer: '',
              supplierContact: "",
              supplierAddress: "",
              lon: '',
              lat: '',
            },
          ],
          suppliersIds: [],
        }
      }
    }
  },
};
</script>

<style lang="scss">
.add-parts-retrieval-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    // background: #4278c9;
    .el-dialog__title {
      font-size: 16px;
      font-weight: bold;
      color: #333333;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        font-weight: bold;
        color: #333333;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
  .append-column {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    font-size: 14px;
    color: #4278C9;
    cursor: pointer;
    border: 1px solid #DEE3E6;
    border-top: none;
    margin-top: -12px;
    margin-left: 12px;
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .address-button {
    font-size: 14px;
    color: #3470FF;
    text-decoration:underline;
    cursor: pointer;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    width: 130px;
    height: 130px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 130px;
    height: 130px;
    line-height: 130px;
    text-align: center;
  }
  .avatar {
    width: 130px;
    height: 130px;
    display: block;
  }
}
</style>
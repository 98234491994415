<template>
  <el-dialog title="提示"
    :visible.sync="dialogVisible"
    custom-class="policy-dialog"
    width="1162px" append-to-body
    :before-close="handleClose">
    <div class="head-input">
      <img src="@/assets/images/policy/policyPosition.png"
        style="width: 20px;height:20px;"
        alt
        srcset />

      <el-input id="tipInput"
        style="width: 534px;margin-right: 24px;margin-left: 12px;"
        v-model="inputSearchVal"
        @keyup.enter.native="searchKeyword(inputSearchVal)"
        placeholder="请输入地址"></el-input>
      <el-button type="primary"
        plain
        @click="searchKeyword(inputSearchVal)">搜索</el-button>
      <el-button type="primary"
        @click="handleSubmit">确定</el-button>
      <!-- 搜索结果栏 -->
      <div class="tool_search_result"
        v-if="showsearchResult">
        <ul>
          <li @click="markerResult(item)"
            v-for="(item,index) in poiList"
            :key="index">{{item.name}}</li>
        </ul>
      </div>
    </div>
    <div ref="container"
      style="width: 1112px;height: 540px;position: relative;">
      <div class="floating-frame">
        <div class="floating-frame-text"
          v-if="addressVal">{{ addressVal }}</div>
        <img src="@/assets/images/policy/positionImage.png"
          @click="isPositionDialog = true"
          style="width: 56px;height:56px;display: block;margin: auto;"
          alt
          srcset />
      </div>
    </div>
    <div style="font-size:14px;color: #666666;margin-top:16px">提示：请拖动地图选择地址(如果定位地址与当前地址距离较远，请将鼠标移入地图后长按鼠标拖动地图进行选择。注：抓取地图不放后鼠标滚轮缩放地图定位)</div>
  </el-dialog>
</template>

<script>
import { newGetCompleteAddress } from '@/components/AMap/utils';
export default {
  props: {
    showDialog: Boolean,
    type: {
      type: String,
      default: 'complete'
    },
    addressValue: {
      type: String,
      default: ''
    }
  },
  model: {
    prop: 'showDialog',
    event: 'change'
  },
  watch: {
    dialogVisible: {
      handler(v) {
        if (!v) {
          this.$emit('change', false);
        }
      }
    },
    showDialog: {
      handler(v) {
        if (v) {
          this.dialogVisible = true;
          this.init();
        } else {
          this.dialogVisible = false;
          this.inputSearchVal = '';
          this.addressVal = '';
        }
      }
    }
  },
  data() {
    return {
      dialogVisible: false,
      fileList: [],
      placeSearch: null,
      showsearchResult: false,
      autoCompleteComponent: undefined,
      placeSearchComponent: undefined,
      markerList: [],
      mapInput: '',
      inputSearchVal: '',
      poiList: [],
      mapall: null,
      addressVal: '',
      addressObj: {}
    };
  },
  created() {
  },
  methods: {
    init() {
      const that = this;
      this.$nextTick(() => {
        if (that.mapall) {
          var citySearch = new AMap.CitySearch();
          citySearch.getLocalCity(function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              that.addressValue ? that.searchKeyword(that.addressValue, true) : "";
              let rectangle = result.rectangle.split(';');
              let rectangle1 = rectangle[0].split(',');
              let rectangle2 = rectangle[1].split(',');
              let Lng = Number(rectangle1[0]) + (((Number(rectangle2[0]) - Number(rectangle1[0])) * 0.2));
              let Lat = Number(rectangle1[1]) + (((Number(rectangle2[1]) - Number(rectangle1[1])) * 0.2));
              that.mapall.setCenter([Lng, Lat]);
              var center = that.mapall.getCenter(); //获取当前地图中心位置
              newGetCompleteAddress(center.lng, center.lat).then(res => {
                that.addressVal = res.completeAddress;
                res.lon = center.lng;
                res.lat = center.lat;
                that.addressObj = res;
              });
            }
          });
        } else {
          // ip定位
          AMap.plugin('AMap.CitySearch', function () {
            var citySearch = new AMap.CitySearch();
            citySearch.getLocalCity(function (status, result) {
              if (status === 'complete' && result.info === 'OK') {
                let rectangle = result.rectangle.split(';');
                let rectangle1 = rectangle[0].split(',');
                let rectangle2 = rectangle[1].split(',');
                let Lng = Number(rectangle1[0]) + (((Number(rectangle2[0]) - Number(rectangle1[0])) * 0.2));
                let Lat = Number(rectangle1[1]) + (((Number(rectangle2[1]) - Number(rectangle1[1])) * 0.2));
                // 查询成功，result即为当前所在城市信息

                var map = new AMap.Map(that.$refs.container, {
                  resizeEnable: true,
                  center: [Lng, Lat]
                });
                that.mapall = map;
                AMap.plugin('AMap.Autocomplete', function () {
                  that.mapSearchInit();
                  that.addressValue ? that.searchKeyword(that.addressValue, true) : "";
                  //输入提示
                  // var autoOptions = {
                  //   input: "keyword"
                  // };
                  // var auto = new AMap.Autocomplete(autoOptions);
                  // AMap.plugin('AMap.PlaceSearch', function () {
                  //   let placeSearch = new AMap.PlaceSearch({
                  //     map: map
                  //   }); //构造地点查询类
                  //   AMap.event.addListener(auto, "select", select); //注册监听，当选中某条记录时会触发
                  //   AMap.event.addListener(auto, "error", onError); //注册监听，当选中某条记录时会触发
                  //   function select(e) {
                  //     that.markerResult(e);
                  //     placeSearch.setCity(e.poi.adcode);
                  //     placeSearch.search(e.poi.name); //关键字查询查询
                  //     var info = e.poi;//地理信息
                  //   }
                  //   function onError(data) {
                  //     console.log(data);
                  //     console.log("定位失败");
                  //     mui.alert(data.info);
                  //   }
                  // });
                  logMapinfo();
                });


                //显示地图层级与中心点信息
                function logMapinfo(e) {
                  var zoom = map.getZoom(); //获取当前地图级别
                  var center = map.getCenter(); //获取当前地图中心位置
                  newGetCompleteAddress(center.lng, center.lat).then(res => {
                    that.addressVal = res.completeAddress;
                    that.inputSearchVal = res.completeAddress;
                    res.lon = center.lng;
                    res.lat = center.lat;
                    that.addressObj = res;
                  });
                };

                //绑定地图移动与缩放事件
                map.on('moveend', logMapinfo);
                map.on('zoomend', logMapinfo);
              }
            });
          });
        }
      });
    },
    search() {
      this.is1 = !this.is1;
      this.toolSerach = !this.toolSerach;
      if (this.toolSerach === false) {
        this.showsearchResult = false;
        //清除结果标点，暂时还没做
      }
    },
    markerResult(data) {
      // if (this.markerObj) {
      //   this.mapall.remove(this.markerObj);
      //   this.markerObj = null;
      // }
      // this.markerObj = new AMap.Marker({
      //   position: [Number(data.location.lng), Number(data.location.lat)],
      //   cursor: 'pointer',
      //   // icon: carIcon,
      //   autoRotation: true,
      //   angle: 0,
      //   offset: new AMap.Pixel(-36, -30),
      // });
      // this.markerObj.setMap(this.mapall);
      this.inputSearchVal = data.name;
      this.showsearchResult = false;
      this.mapall.setCenter([Number(data.location.lng), Number(data.location.lat)]);
      this.mapall.setZoom(999);
    },
    /** 初始化搜索工具 */
    mapSearchInit() {   //在mounted中调用
      const that = this;
      var autoOptions = {
        input: "tipInput",
      };
      var autoCompleteComponent = new AMap.Autocomplete(autoOptions);
      this.autoCompleteComponent = autoCompleteComponent;
      AMap.plugin('AMap.PlaceSearch', () => {
        // 注册placeSearch组件
        that.placeSearchComponent = new AMap.PlaceSearch();
      });
      AMap.plugin('AMap.Marker', function () {
      });
    },
    searchKeyword(inputSearchVal, type = false) {
      let cur = this;
      this.placeSearchComponent.search(inputSearchVal, function (status, result) {
        // 查询成功时，result即对应匹配的POI信息
        if (status === 'complete' && result.info === "OK") {
          if (type) {
            cur.mapall.setCenter([result.poiList.pois[0].location.lng, result.poiList.pois[0].location.lat]);
            cur.mapall.setZoom(999);
          } else {
            cur.showsearchResult = true;
            cur.poiList = result.poiList.pois;
          }
        } else {
          cur.showsearchResult = false;
          cur.poiList = [];
          cur.$message({
            message: "没有查到结果",
            type: "warning",
          });
        }
      });
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => { });
    },
    handleSubmit() {
      if (this.type == 'complete') {
        this.$emit('submit', this.addressVal);
      } else if (this.type == 'address') {
        this.$emit('submit', this.addressObj.address);
      } else {
        this.$emit('submit', this.addressObj);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.amap-sug-result {
  display: none;
}
.floating-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
  z-index: 99;
  &-text {
    background: #fff;
    color: #ffaa00 !important;
    border: 1px solid #ffaa00 !important;
    padding: 6px;
    border-radius: 6px;
  }
}
.head-input {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
</style>
<style lang="scss">
.policy-dialog {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  .el-dialog__header {
    font-size: 18px;
    font-weight: bold;
    height: 40px;
    line-height: 40px;
    padding: 0 24px;
    background: #4278c9;
    .el-dialog__title {
      color: #fff;
    }
    .el-dialog__headerbtn {
      top: 11px;
      .el-dialog__close {
        color: #fff;
        font-weight: bold;
      }
    }
  }
  .el-dialog__body {
    padding: 24px;
  }
  &-template {
    height: 60px;
    line-height: 60px;
    background: #f3f8ff;
    font-size: 14px;
    color: #333333;
    border-radius: 8px;
    border: 1px dashed #d1e3fe;
    padding: 0 24px;
    margin-bottom: 12px;
    &-span {
      color: #0080ff;
      cursor: pointer;
    }
  }
  &-b {
    height: auto;
    padding: 10px 24px;
    &-img {
      display: block;
      width: 40px;
      height: 40px;
      margin: 14px auto 12px;
    }
    &-text {
      text-align: center;
      font-size: 18px;
      margin-bottom: 5px;
      color: #333333;
    }
    .upload-demo {
      width: 100%;
      .el-upload {
        width: 100%;
      }
      .el-upload-dragger {
        width: 100%;
        height: auto;
        border: none;
        background-color: transparent;
      }
    }
  }
  .tool_search {
    width: 250px;
    top: 42px;
    right: 270px;
    height: 32px;
    background: #eee;
    opacity: 0.8;
    border-radius: 4px;
    margin-top: 2px;
    bottom: auto;
    z-index: 12;
    position: absolute;
    text-align: left;
    font-size: 14px;
  }
  ::v-deep .el-input-group__append,
  .el-input-group__prepend {
    border: 1px solid rgb(194, 193, 193);
    border-left: none;
    background: rgb(231, 230, 230);
    color: rgb(77, 77, 73);
  }
  ::v-deep .el-input__inner {
    border: 1px solid rgb(194, 193, 193);
  }
  .tool_search_result {
    width: 532px;
    top: 30px;
    left: 33px;
    height: 300px;
    border: 1px solid rgb(175, 175, 173);
    border-top: none;
    background: #fff;
    opacity: 0.8;
    margin-top: 2px;
    bottom: auto;
    z-index: 12;
    position: absolute;
    text-align: left;
    font-size: 14px;
  }
  .tool_search_result ul {
    width: 100%;
    height: 100%;
    list-style: none;
    overflow: auto;
    margin: 0;
    padding: 0;
  }
  .tool_search_result ul li {
    font-size: 12px;
    color: rgb(23, 40, 75);
    text-align: center;
    width: 100%;
    height: 36px;
    line-height: 36px;
    float: left;
    margin: 0px 0px 1px 0;
    padding: 0 4px 0 4px;
    border-bottom: 1px dashed rgb(170, 170, 172);
    display: inline-block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: border-box;
  }
  .tool_search_result ul li:last-child {
    border: none;
  }
}
</style>
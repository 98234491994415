/**
 * 高德轨迹纠偏
 * https://lbs.amap.com/api/javascript-api/reference/grasproad
 */

import { loadPlugin } from './loadPlugin';

// 默认轨迹第一个采集点的时间
const defaultTm = 1537238984;
// 默认方向
const defaultAg = 0;
// 默认轨迹点速度
const defaultSp = 0;
// 时间增速步伐, 如果第一个点没有时间, 则以`tmStep`增加
const tmStep = 30;

let graspRoad = null;

/**
 * 轨迹纠偏
 * NOTE: 调用前保证 `AMap`, `AMap.GraspRoad`已经加载
 * @param {Array<{lng, lat, angle, speed, time}>}
 * @param {number} lng - 经度
 * @param {number} lat - 纬度
 * @param {number} angle - 方向角度 [0,360], 可不填
 * @param {number} speed - 速度, 可不填
 * @param {number} time - 时间(秒), 可不填
 * @return {{distance, path}} 
 * @return { number} distance - 总距离
 * @return { Array<[lng,lat]>} 
 */
export async function graspDriving(path) {

  await loadPlugin(['AMap.GraspRoad']);

  return new Promise((resolve, reject) => {
    if (path.length <= 3) return resolve();

    const total = path.length;

    const list = [];
    const { lng, lat, time, angle, speed } = path.shift();
    const firstTm = time;
    // 第一个点
    list.push({
      x: lng, // 经度
      y: lat, // 纬度
      ag: angle || defaultAg,
      sp: speed || defaultSp,
      tm: time || defaultTm,
    });

    while (path.length) {
      const { lng, lat, time, angle, speed } = path.shift();

      list.push({
        x: lng,
        y: lat,
        ag: angle || defaultAg,
        sp: speed || defaultSp,
        tm: firstTm ? (time - firstTm) : (total - path.length - 1) * tmStep,
      });
    }

    if (!graspRoad) {
      graspRoad = new AMap.GraspRoad();
    }

    graspRoad.driving(list, (error, result) => {
      if (error) return reject(error);

      const { distance, points } = result.data;
      resolve({
        distance,
        path: points.map(lngLat => [lngLat.x, lngLat.y])
      });
    });


  });
}
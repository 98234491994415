
import { sleepIf } from '@/utils/sleep';

/**
 * 插件加载方法。参数name中指定需要加载的插件类型，
 * 同时加载多个插件时，以字符串数组的形式添加。
 * rg: AMap.ToolBar, AMap.OverView
 */
export async function loadPlugin(pluginNames) {

  // 这里是保证高德地图脚本已经加载完成
  await sleepIf(8000, () => window.AMap);

  return new Promise((resolve, reject) => {
    const plugins = pluginNames.filter(name => !window[name]);
    if (plugins.length === 0) return resolve();
    AMap.plugin(plugins, () => {
      resolve();
    });
  });
}
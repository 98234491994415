
import { loadMap } from './loadMap';
import { createMap } from './createMap';
import { graspDriving } from './graspDriving';
import { MapMouseTool } from './mouseTool';

import { districtSearch } from './districtSearch';

import { getAddress, getAddresses, newGetAddress, newGetCompleteAddress } from './geocoder';

import { placeSearch } from './placeSearch';


// 安全密钥jscode
const securityJsCode = 'b832abf8507ba6e6961003143b6cb351';
// key值
const amapKey = '976c0b4ad96fe99e57ce62bffa913785';

const amapUrl = `https://webapi.amap.com/maps?v=1.4.15&key=${ amapKey }`;
// 异步加载脚本, 使用时需要验证是否加载完成 window.AMap === undefined
{/* < script type="text/javascript" src="https://webapi.amap.com/maps?v=1.4.15&key=ef6228562698355ce05573320d6e5cfa" ></script > */ }
// 如果已经加载了, 则直接返回 window.AMap !== undefined
loadMap(amapUrl, securityJsCode);

export {
  createMap,
  graspDriving,
  MapMouseTool,
  districtSearch,
  getAddress,
  newGetAddress,
  newGetCompleteAddress,
  getAddresses,
  placeSearch
};
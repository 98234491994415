
/**
 * 地区搜索
 */


import { loadPlugin } from './loadPlugin';

let place = null;

/**
 * 
 * @param {*} keyWord // 输入城市
 * @returns 
 */

export async function placeSearch(keyWord) {
  await loadPlugin(['AMap.PlaceSearch']);
  if (!place) {
    place = new AMap.PlaceSearch({
      city: '全国', // 兴趣点城市
      citylimit: false,  //是否强制限制在设置的城市内搜索
      autoFitView: true // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
    });
  }

  return new Promise((resolve) => {
    place.search(keyWord, (status, result) => {
      if (status === 'complete') {

        const { poiList } = result;


        resolve(poiList.pois);
      }
    });
  });

}
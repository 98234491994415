/**
 * 行政区域搜索
 */


import { loadPlugin } from './loadPlugin';


let district = null;

/**
 * @param {number} adcode - 行政代码
 * @param {string} level - 行政级别
 */
export async function districtSearch(adcode, level = 'province') {
    await loadPlugin(['AMap.DistrictSearch']);
    if (!district) {
        //注意：需要使用插件同步下发功能才能这样直接使用
        district = new AMap.DistrictSearch({
            // 不返回下级行政区
            subdistrict: 0, //
            showbiz: false // 最后一级返回街道信息
        });
    }
    district.setLevel(level); //行政区级别
    district.setExtensions('all');

    return new Promise((resolve) => {
        //按照adcode进行查询可以保证数据返回的唯一性
        district.search(adcode, (status, result) => {
            if (status === 'complete') {
                // const bounds = []; //    getData(result.districtList[0],obj.id);
                resolve(result.districtList[0].boundaries);
            }
        });
    });
}
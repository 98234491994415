/**
 * 加载地图脚本
 */


import loadScript from '@/utils/loadScript';


/** 加载高德地图脚本, 已经加载直接返回 */
export async function loadMap(url, securityJsCode = null) {
  if (window.AMap) return window.AMap;

  // https://lbs.amap.com/api/javascript-api/guide/abc/prepare
  // 自2021年12月02日升级，升级之后所申请的 key 必须配备安全密钥 jscode 一起使用
  if (typeof window._AMapSecurityConfig === 'undefined') {
    window._AMapSecurityConfig = {};
  }
  if (securityJsCode) {
    window._AMapSecurityConfig.securityJsCode = securityJsCode;
  }


  await loadScript(url);
  return window.AMap;
}
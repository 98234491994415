/**
 * 封装高德逆地址解析
 */

import { loadPlugin } from "./loadPlugin";
import GPS from "@/utils/address";

// https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address#m_AMap.Geocoder

let geocoder = null;

async function initGeocoder() {
  if (geocoder) return;
  await loadPlugin(["AMap.Geocoder"]);
  geocoder = new AMap.Geocoder({
    // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
    city: "全国",
    batch: true,
    extensions: "all", // all
    radius: 100,
  });
}

/**
 * wgs84
 * 单个获取地址
 */
export async function getAddress(lon, lat) {
  await initGeocoder();
  const point = GPS.delta(parseFloat(lat), parseFloat(lon));

  return new Promise((resolve) => {
    geocoder.getAddress([point.lon, point.lat], (status, result) => {
      // https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address
      if (status === "complete" && result.info === "OK" && result.regeocode) {
        // result为对应的地理位置详细信息
        return resolve(result.regeocode.formattedAddress);
      }
      return resolve("");
    });
  });
}

/**
 * wgs84
 * 单个获取地址--准确版--
 */
export async function newGetAddress(lon, lat) {
  await initGeocoder();
  return new Promise((resolve) => {
    geocoder.getAddress([Number(lon), Number(lat)], (status, result) => {
      // https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address
      if (status === "complete" && result.info === "OK" && result.regeocode) {
        // result为对应的地理位置详细信息
        return resolve(result.regeocode.formattedAddress);
      }
      return resolve("");
    });
  });
}

/**
 * wgs84
 * 单个获取地址--准确+省市区街道详细地址分开 版
 */
export async function newGetCompleteAddress(lon, lat) {
  await initGeocoder();
  const point = GPS.delta(parseFloat(lat), parseFloat(lon));

  return new Promise((resolve) => {
    geocoder.getAddress(
      [Number(point.lon), Number(point.lat)],
      (status, result) => {
        // https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address
        if (status === "complete" && result.info === "OK" && result.regeocode) {
          // result为对应的地理位置详细信息
          return resolve({
            province: result.regeocode.addressComponent.province,
            city: result.regeocode.addressComponent.city,
            district: result.regeocode.addressComponent.district,
            township: result.regeocode.addressComponent.township,
            street:
              result.regeocode.addressComponent.street ||
              (result.regeocode.roads && result.regeocode.roads.length
                ? result.regeocode.roads[0].name
                : ""),
            building:
              result.regeocode.addressComponent.building ||
              (result.regeocode.aois && result.regeocode.aois.length
                ? result.regeocode.aois[0].name
                : ""),
            address: result.regeocode.formattedAddress.split(
              result.regeocode.addressComponent.district
            )[1],
            completeAddress: result.regeocode.formattedAddress,
            secondId:
              result?.regeocode?.aois[0]?.id || result?.regeocode?.pois[0]?.id,
          });
        }
        return resolve("");
      }
    );
  });
}

/**
 * wgs84
 * Array<{lon,lat,tag}>
 * 批量获取地址
 */

export async function getAddresses(points) {
  const allPromise = [];
  // 分批加载, 高德一次最多支持20个经纬度
  for (let i = 0; i < points.length; i += 20) {
    allPromise.push(getAddressBatch(points.slice(i, i + 20)));
  }
  return Promise.all(allPromise)
    .then((arr) => arr.flat())
    .catch(() => []);
}

/**
 * wgs84
 * Array<{lon,lat,tag}>
 * 最多20个,
 */
async function getAddressBatch(points) {
  console.log(points);
  await initGeocoder();
  // Array<{tag,lon,lat}>
  points = points.slice(0, 20).map((obj) => ({
    tag: obj.tag,
    ...GPS.delta(obj.lat, obj.lon),
  }));

  const lnglats = points.map((obj) => [obj.lon, obj.lat]);
  // 20231219003
  return new Promise((resolve) => {
    let addressList = [];
    let ind = 0;
    geocoder.getAddress(lnglats, (status, result) => {
      // https://lbs.amap.com/api/javascript-api/reference/lnglat-to-address
      if (status === "complete" && result.regeocodes.length) {
        // obj.lon, obj.lat
        for (let i = 0; i < lnglats.length; i++) {
          if (!points[i].lon || !points[i].lat) {
            addressList.push({
              tag: i, // 下标对应
              address: "--",
            });
          } else {
            const regeocode = result.regeocodes[ind];
            ind += 1;
            addressList.push({
              tag: points[i].tag, // 下标对应
              address: regeocode.formattedAddress,
            });
          }
        }
        // console.log(addressList);
      } else {
        for (let i = 0; i < points.length; i++) {
          addressList.push({
            tag: i, // 下标对应
            address: "--",
          });
        }
      }
      return resolve(addressList);
    });
  });
}
